import React, { useState, useEffect } from "react";
import { ref as dbRef, update, get, child, set } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { database, storage } from "../firebase";
import { TextField, Button, Grid, Typography, Container, Box, FormControlLabel, Checkbox } from '@mui/material';
import swal from "sweetalert"

const EditInterior = ({ constructionId= "edit-interior" }) => {
  const [data, setData] = useState({
    tenGiaChu: '',
    diaDiemThiCong: '',
    dienTichDat: '',
    dienTichXayDung: '',
    soTang: ''
  });
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');  // State để hiển thị ảnh đã có
  const [newTienIch, setNewTienIch] = useState(''); // Dùng để nhập tiện ích mới
  const [tienIchList, setTienIchList] = useState([]); // Danh sách tiện ích có thể thay đổi
  // Lấy dữ liệu từ Firebase khi component render
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dbSnapshot = await get(child(dbRef(database), `constructions/${constructionId}`));
        if (dbSnapshot.exists()) {
          const constructionData = dbSnapshot.val();
          setData({
            tenGiaChu: constructionData.tenGiaChu,
            diaDiemThiCong: constructionData.diaDiemThiCong,
            dienTichDat: constructionData.dienTichDat,
            dienTichXayDung: constructionData.dienTichXayDung,
            soTang: constructionData.soTang,
            tienIch: constructionData.tienIch || [] 
          });
          setImageUrl(constructionData.imageUrl || '');  // Hiển thị ảnh đã lưu
        } else {
          console.log("No data available");
        }
      } catch (e) {
        console.error("Error fetching data: ", e);
      }
    };

    fetchData();
  }, [constructionId]);

  const handleUpdateConstructionInfo = async (data, imageFile) => {
    try {
      let newImageUrl = imageUrl;

      if (imageFile) {
        const imageStorageRef = storageRef(storage, `images/${imageFile.name}`);
        await uploadBytes(imageStorageRef, imageFile);
        newImageUrl = await getDownloadURL(imageStorageRef);
      }
      const dbRef1 = dbRef(database, `constructions/${constructionId}`);
      const snapshot = await get(dbRef1);
      console.log("snapshot.exists()", snapshot.exists())
      if(snapshot.exists()) {
        // Cập nhật thông tin vào ID cụ thể trong Realtime Database
        await update(dbRef1, {
          ...data,
          imageUrl: newImageUrl,  // Đường dẫn ảnh mới
          timestamp: new Date().toISOString(),
        });
       
      }
      else {
        await set(dbRef1, {
            ...data,
            imageUrl: newImageUrl,  // Đường dẫn ảnh mới
            timestamp: new Date().toISOString(),
          });
      }

      console.log("Dữ liệu đã được cập nhật cho ID: ", constructionId);
      swal("", "Cập nhật thành công", "success").then(()=> window.location.reload())
    } catch (e) {
      console.error("Error updating data: ", e);
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setData((prevData) => ({
      ...prevData,
      tienIch: {
        ...prevData.tienIch,
        [name]: checked
      }
    }));
  };

  const handleAddTienIch = () => {
    if (newTienIch.title && newTienIch.value && newTienIch.unit && newTienIch.price) {
      setData((prevData) => ({
        ...prevData,
        tienIch: [...prevData.tienIch, newTienIch] // Thêm tiện ích mới vào danh sách
      }));
      // Clear input sau khi thêm
      setNewTienIch({
        title: '',
        value: '',
        unit: '',
        price: ''
      });
    }
  };

  const handleNewTienIchChange = (e) => {
    const { name, value } = e.target;
    setNewTienIch((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDeleteTienIch = (index) => {
    setData((prevData) => ({
      ...prevData,
      tienIch: prevData.tienIch.filter((_, i) => i !== index) // Xóa tiện ích theo index
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdateConstructionInfo(data, imageFile);
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Cập nhật thông tin công trình nội thất
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Hàng 1"
                name="tenGiaChu"
                value={data.tenGiaChu}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Hàng 2"
                name="diaDiemThiCong"
                value={data.diaDiemThiCong}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Hàng 3"
                name="dienTichDat"
                value={data.dienTichDat}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Hàng 4"
                name="dienTichXayDung"
                value={data.dienTichXayDung}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            {/* Input để nhập tiện ích mới */}

            {/* Hiển thị danh sách checkbox các tiện ích */}
            {/* Input để nhập các thuộc tính của tiện ích */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Tên tiện ích"
                name="title"
                value={newTienIch.title}
                onChange={handleNewTienIchChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Giá trị tiện ích"
                name="value"
                value={newTienIch.value}
                onChange={handleNewTienIchChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Đơn vị"
                name="unit"
                value={newTienIch.unit}
                onChange={handleNewTienIchChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Giá"
                name="price"
                value={newTienIch.price}
                onChange={handleNewTienIchChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleAddTienIch} sx={{ mt: 2 }}>
                Thêm tiện ích
              </Button>
            </Grid>

            {/* Hiển thị danh sách các tiện ích đã thêm */}
            {data.tienIch?.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Typography variant="body1">
                  {item.title} - {item.value} - {item.unit} - {item.price} VND
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteTienIch(index)}
                    sx={{ ml: 2 }}
                  >
                    Xóa
                  </Button>
                </Typography>
              </Grid>
            ))}
            {/*  */}
            <Grid item xs={12}>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ marginTop: '16px' }}
              />
            </Grid>
            <Grid item xs={12}>
              {imageUrl && (
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    maxHeight: 200,
                    objectFit: 'contain',
                    mt: 2,
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                  src={imageUrl}
                  alt="Uploaded Image"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Cập nhật thông tin
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default EditInterior;
