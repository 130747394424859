import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database'
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyA3-uUK1rnF_scoW7p-dKBkDPZcXJ4LBy8",
    authDomain: "tinhnha-86628.firebaseapp.com",
    databaseURL: "https://tinhnha-86628-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "tinhnha-86628",
    storageBucket: "tinhnha-86628.appspot.com",
    messagingSenderId: "964188954648",
    appId: "1:964188954648:web:f48e75df57e96ec93ba36d",
    measurementId: "G-G3Q54RXN0Y"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const database = getDatabase(app);
const storage = getStorage(app);
export { db, database, storage };