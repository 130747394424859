import { child, get } from "firebase/database";
import React, { Fragment, useEffect, useState } from "react";
import { ref as dbRef, update } from "firebase/database";
import { database } from "../firebase";
import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const BuildForInterior = ({ constructionId = "edit-interior" }) => {
  const [loading, setLoading] = useState();
  const [dataTitle, setDataTitle] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [formData, setFormData] = useState({
    ownerName: "",
    location: "",
    landArea: "",
    buildArea: "",
    floors: 0,
  });

  const [checkedFeatures, setCheckedFeatures] = useState([]);
  const [activePackage, setActivePackage] = useState("standard");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFeatureChange = (e) => {
    const { value, checked } = e.target;
    setCheckedFeatures((prevFeatures) =>
      checked
        ? [...prevFeatures, dataTitle?.tienIch?.find(item=> item?.value=== value)]
        : prevFeatures.filter((f) => f?.value !== value)
    );
  };

  const handlePackageClick = (pkg) => {
    setActivePackage(pkg);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { ownerName, location, landArea, buildArea, floors } = formData;

    // Validation logic
    if (
      !ownerName ||
      !location ||
      !landArea ||
      isNaN(landArea) ||
      landArea <= 0 ||
      !buildArea ||
      isNaN(buildArea) ||
      buildArea <= 0 ||
      !floors ||
      isNaN(floors) ||
      floors <= 0
    ) {
      alert("Vui lòng nhập đầy đủ và hợp lệ các trường dữ liệu.");
      return;
    }

    // Calculate total area
    const atticArea = 25;
    const multiplier100 = 100;
    const multiplier50 = 50;
    const multiplier30 = 30;

    const totalGroudFloorArea = (buildArea * multiplier100) / 100;
    const total1thFloorArea = (buildArea * multiplier100) / 100;
    const total2ndFloorArea = (buildArea * multiplier100) / 100;
    const totalAtticFloorArea = (atticArea * multiplier100) / 100;
    const rooftopArea = (buildArea * multiplier50) / 100;
    const gardenWithFenceGateArea =
      ((landArea - buildArea) * multiplier30) / 100;

    const grandTotalArea =
      (totalGroudFloorArea + total1thFloorArea + total2ndFloorArea) * floors +
      rooftopArea +
      gardenWithFenceGateArea;
    const grandTotalRaw =
      parseInt(totalGroudFloorArea) +
      parseInt(total1thFloorArea) +
      parseInt(total2ndFloorArea) +
      parseInt(totalAtticFloorArea);
    let formDataSubmit = {
      ownerName: ownerName,
      location: location,
      area: landArea,
      buildArea: buildArea,
      floors: floors,
      grandTotalArea,
      grandTotalRaw,
    };
    printReport(formDataSubmit);
    console.log("Grand Total Area: ", grandTotalArea);
    // Call your print function or further processing
  };

  function printReport(data) {
    const content = `
            <div class="container">
                <img src="https://res.cloudinary.com/cockbook/image/upload/v1726201759/single/Screenshot_2024-09-13_112809_w1insy.png" />
                <div class="header">
                    <h1>Customer Information</h1>
                </div>
                <div class="customer-info">
                    <p><strong>Customer name:</strong> ${data?.ownerName}</p>
                    <p><strong>Address:</strong> ${data?.location}</p>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>STT<br><small>No.</small></th>
                            <th>NỘI DUNG<br><small>Content</small></th>
                            <th>KHU VỰC<br><small>Zone</small></th>
                            <th>SỐ LƯỢNG<br><small>Quantity</small></th>
                            <th>ĐƠN VỊ<br><small>Unit</small></th>
                            <th>HỆ SỐ<br><small>Ratio</small></th>
                            <th>DIỆN TÍCH<br><small>Design area</small></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>A</td>
                            <td>LAYOUT + CONCEPT</td>
                            <td>Full</td>
                            <td>1</td>
                            <td>Gói</td>
                            <td>0%</td>
                            <td>000,00</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>HỒ SƠ THIẾT KẾ</td>
                            <td>Tầng trệt</td>
                            <td>${data?.buildArea}</td>
                            <td>m<sup>2<sup></td>
                            <td>100%</td>
                            <td>${data?.buildArea}</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td></td>
                            <td>Tầng 2</td>
                            <td>${data?.buildArea}</td>
                            <td>m<sup>2<sup></td>
                            <td>100%</td>
                            <td>${data?.buildArea}</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td></td>
                            <td>Tầng 3</td>
                            <td>${data?.buildArea}</td>
                            <td>m<sup>2<sup></td>
                            <td>100%</td>
                            <td>${data?.buildArea}</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td></td>
                            <td>Tầng thượng</td>
                            <td>25</td>
                            <td>m<sup>2<sup></td>
                            <td>100%</td>
                            <td>${25}</td>
                        </tr>
                        <tr>
                            <td colspan="6" class="total"><span>TOTAL: (2+3+4+5)</span></td>
                            <td>${data?.grandTotalRaw}</td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <tr>
                        <th colspan="7" class="section-title">MỨC ĐẦU TƯ XÂY DỰNG</th>
                    </tr>
                    <tr>
                        <th>STT<br>No.</th>
                        <th>NỘI DUNG<br>Content</th>
                        <th>KHU VỰC<br>Zone</th>
                        <th>SỐ LƯỢNG<br>Quantity</th>
                        <th>ĐƠN VỊ<br>Unit</th>
                        <th>HỆ SỐ<br>Ratio</th>
                        <th>DIỆN TÍCH<br>Design area</th>
                    </tr>
                    <tr>
                        <td rowspan="7">1</td>
                        <td rowspan="7">FULL HOUSE</td>
                        <td>Móng cọc + sàn BTCT<br>(không bao gồm cọc)</td>
                        <td>${data?.buildArea}</td>
                        <td>m<sup>2</sup></td>
                        <td>50%</td>
                        <td>${data?.buildArea * 0.5}</td>
                    </tr>
                    <tr>
                        <td>Tầng trệt</td>
                        <td>${data?.buildArea}</td>
                        <td>m<sup>2</sup></td>
                        <td>100%</td>
                        <td>${data?.buildArea}</td>
                    </tr>
                    <tr>
                        <td>Lầu 1</td>
                        <td>${data?.buildArea}</td>
                        <td>m<sup>2</sup></td>
                        <td>100%</td>
                        <td>${data?.buildArea}</td>
                    </tr>
                    <tr>
                        <td>Lầu 2</td>
                        <td>${data?.buildArea}</td>
                        <td>m<sup>2</sup></td>
                        <td>100%</td>
                        <td>${data?.buildArea}</td>
                    </tr>
                    <tr>
                        <td>Tum</td>
                        <td>25</td>
                        <td>m<sup>2</sup></td>
                        <td>100%</td>
                        <td>25</td>
                    </tr>
                    <tr>
                        <td>Sân thượng BTCT</td>
                        <td>${data?.buildArea}</td>
                        <td>m<sup>2</sup></td>
                        <td>50%</td>
                        <td>${data?.buildArea * 0.5}</td>
                    </tr>
                    <tr>
                        <td>Sân vườn (bao gồm cổng rào)</td>
                        <td>${data?.area - data?.buildArea}</td>
                        <td>m<sup>2</sup></td>
                        <td>30%</td>
                        <td>${
                          (parseInt(data?.area - data?.buildArea) * 3) / 10
                        }</td>
                    </tr>
                    <tr class="total-row">
                        <td colspan="6">TOTAL</td>
                        <td>${data?.grandTotalArea}</td>
                    </tr>
                </table>
                <table>
                    <thead>
                        <tr>
                            <th>GÓI THI CÔNG</th>
                            <th>DIỆN TÍCH</th>
                            <th>ĐƠN GIÁ</th>
                            <th>THÀNH TIỀN</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>STANDARD CHOICE</td>
                            <td>${data?.grandTotalArea}</td>
                            <td>7,000,000</td>
                            <td>${data?.grandTotalArea * 7000000}</td>
                        </tr>
                        <tr>
                            <td>PROFESSIONAL CHOICE</td>
                            <td>${data?.grandTotalArea}</td>
                            <td>7,500,000</td>
                            <td>${data?.grandTotalArea * 7500000}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="note">
                    <strong>Ghi Chú:</strong>
                    <p>- Báo giá này chưa bao gồm các chi phí sau:</p>
                    <p>- Chi phí giám sát tác giả thiết kế và chi phí xin giấy phép xây dựng</p>
                    <p>- Phương thức thanh toán chia làm 3 đợt:</p>
                    <p>Đợt 1: Bên A thanh toán cho bên B: 50% giá trị hợp đồng ngay sau khi ký hợp đồng và nhận đề nghị thanh toán từ bên B.</p>
                    <p>Đợt 2: Bên A thanh toán cho bên B: 50% giá trị hợp đồng sau khi hoàn thiện 3D.</p>
                    <p>Đợt 3: Bên A thanh toán cho bên B: 20% giá trị hợp đồng còn lại khi bên B bàn giao hồ sơ thiết kế.</p>
                    <p>- Trong thời gian thiết kế, khách hàng được quyền sửa chữa nhưng những thay đổi này giờ như màu sắc, chi tiết, vật liệu đã từng chỉnh sửa thì không được lặp lại và không được sửa đổi phong cách thiết kế đã được thống nhất ngay từ ban đầu không quá 03 lần chỉnh sửa.</p>
                </div>

                <div class="signatures">
                    <div class="signature">
                        XÁC NHẬN CỦA KHÁCH HÀNG<br>
                        (CUSTOMER'S CONFIRMATION)
                        <div>________________________</div>
                    </div>
                    <div class="signature">
                        ĐẠI DIỆN ZENVILLAS<br>
                        (ZENVILLAS REPRESENTATIVES)
                        <div>________________________</div>
                    </div>
                </div>

            </div>
        `;
        const printWindow = window.open('', '', 'height=800,width=600');
        printWindow.document.write('<html><head><title>Print Report</title>');
        printWindow.document.write(`<style>
          img {
            width: 100%;
          }
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
              box-sizing: border-box;
          }
          .container {
              width: 100%;
              max-width: 1200px;
              margin: 0 auto;
              padding: 20px;
          }
          .header {
              background-color: #FFD700;
              padding: 10px;
          }
          .header h1 {
              margin: 0;
              font-size: 24px;
              color: #000;
          }
          .customer-info {
              margin-top: 10px;
              font-size: 18px;
          }
          .customer-info p {
              margin: 5px 0;
          }
          table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
          }
          table, th, td {
              border: 1px solid black;
          }
          th, td {
              padding: 8px;
              text-align: center;
          }
          th {
              background-color: #333;
              color: #fff;
          }
          .total {
              text-align: right;
              padding-right: 20px;
          }
          .total span {
              font-weight: bold;
          }
          .signatures {
            margin-top: 50px;
            display: flex;
            justify-content: space-between;
            text-align: center;
          }
          .signature {
            font-weight: bold;
          }
          .signature div {
            margin-top: 40px;
          }
          .section-title {
            background-color: #d1d1d1;
            font-weight: bold;
            text-align: left;
            padding-left: 10px;
          }
          .row-header {
            background-color: #f7f7f7;
            font-weight: bold;
            text-align: left;
            padding-left: 10px;
          }
          </style>`);
        printWindow.document.write('</head><body>');
        printWindow.document.write(content);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    // const printContent = content;
    // const originContent = document.body.innerHTML;
    // document.body.innerHTML = printContent;
    // wait(500);
    // window.print();
    // document.body.innerHTML = originContent;
    // window.location.reload();
  }
  function wait(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const dbSnapshot = await get(
          child(dbRef(database), `constructions/${constructionId}`)
        );
        if (dbSnapshot.exists()) {
          const constructionData = dbSnapshot.val();
          setDataTitle({
            tenGiaChu: constructionData.tenGiaChu,
            diaDiemThiCong: constructionData.diaDiemThiCong,
            dienTichDat: constructionData.dienTichDat,
            dienTichXayDung: constructionData.dienTichXayDung,
            soTang: constructionData.soTang,
            tienIch: constructionData.tienIch || [] 
          });
          setImageUrl(constructionData.imageUrl || ""); // Hiển thị ảnh đã lưu
        } else {
          console.log("No data available");
        }
      } catch (e) {
        console.error("Error fetching data: ", e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [constructionId]);

  return (
    <div className="container">
      {loading === true && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {loading === false && (
        <Fragment>
          <header>
            <h1>BÁO GIÁ THI CÔNG NỘI THẤT</h1>
            <p>Dự toán chi phí cho căn nhà của bạn</p>
          </header>
          <div className="content">
            <div className="project-type">
              <h2>BÁO GIÁ DỰ ÁN</h2>
              <p>Vui lòng chọn loại công trình</p>
              <div className="project-options options-for-office">
                <label>
                  <input checked type="radio" name="project-type" value="nhapho" /> 
                  <div>
                    <div>Nội thất</div>
                    <div style={{fontSize: 14, fontWeight: 500, textTransform: "capitalize"}}>Chung cư</div>
                  </div>
                </label>
                <label>
                  <input type="radio" name="project-type" value="bietthu" />{" "}
                  <div>
                    <div>Nội thất</div>
                    <div style={{fontSize: 14, fontWeight: 500, textTransform: "capitalize"}}>Nhà, biệt thự</div>
                  </div>
                </label>
              </div>
              <img
                src={imageUrl}
                alt="Construction"
                className="construction-image"
              ></img>
            </div>

            <div className="estimate-form">
              <h2>BẢNG DỰ TRÙ CHI PHÍ CÔNG TRÌNH</h2>
              <div className="package-options">
                <button
                  className={activePackage === "standard" ? "active" : ""}
                  onClick={() => handlePackageClick("standard")}
                >
                  Gói Tiêu Chuẩn
                </button>
                <button
                  className={activePackage === "premium" ? "active" : ""}
                  onClick={() => handlePackageClick("premium")}
                >
                  Gói Nâng Cao
                </button>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>{dataTitle?.tenGiaChu}:</label>
                  <input
                    type="text"
                    name="ownerName"
                    value={formData.ownerName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>{dataTitle?.diaDiemThiCong}:</label>
                  <input
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>{dataTitle?.dienTichDat}:</label>
                  <input
                    type="text"
                    name="landArea"
                    value={formData.landArea}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>{dataTitle?.dienTichXayDung}:</label>
                  <input
                    type="text"
                    name="buildArea"
                    value={formData.buildArea}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="checkbox-group">
                  {dataTitle?.tienIch?.map((item, key)=> <label className="custom-checkbox" key={key}>
                    <input
                    
                      type="checkbox"
                      value={item?.value}
                      onChange={handleFeatureChange}
                    />{" "}
                    <span className="title">{item?.title}</span>
                  </label>)}
                  {/* <label>
                    <input
                      type="checkbox"
                      value="ham"
                      onChange={handleFeatureChange}
                    />{" "}
                    Hầm
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      value="santhuong"
                      onChange={handleFeatureChange}
                    />{" "}
                    Sân thượng
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      value="thangmay"
                      onChange={handleFeatureChange}
                    />{" "}
                    Thang máy
                  </label> */}
                  {/* Add more checkboxes as needed */}
                </div>

                {checkedFeatures.length > 0 && (
                  <Table id="featureTable">
                    <TableHead>
                      {/* <tr>
                        <th>Dịch vụ thêm</th>
                        <th>Mô tả</th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr> */}
                    </TableHead>
                    <TableBody>
                      {/* {console.log("checkedFeatures", checkedFeatures)} */}
                      {checkedFeatures.map((feature, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{borderBottom: "1px solid #000"}}>{parseInt(index) + parseInt(1)}</TableCell>
                          <TableCell sx={{borderBottom: "1px solid #000", width: "30%"}} style={{whiteSpace: "nowrap"}}>Dịch vụ thêm</TableCell>
                          <TableCell sx={{borderBottom: "1px solid #000"}}>{feature?.title}</TableCell>
                          <TableCell sx={{borderBottom: "1px solid #000"}}>{1}</TableCell>
                          <TableCell sx={{borderBottom: "1px solid #000"}}>{feature?.unit}</TableCell>
                          <TableCell sx={{borderBottom: "1px solid #000"}}>{feature?.price}</TableCell>
                          <TableCell sx={{borderBottom: "1px solid #000"}}>{feature?.price}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
                <Box mt={3} sx={{display: "flex", justifyContent: "center"}}>
                  <button id="submit-button" type="submit">
                    Xác Nhận
                  </button>
                </Box>
              </form>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default BuildForInterior;
