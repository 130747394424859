import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './page/home';
import Edit from './page/edit';
import BuildForOffice from './section/BuildForOffice';
import EditOffice from './page/edit-office';
import EditInterior from './page/edit-interior';
import BuildForInterior from './section/BuildForInterior';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/office' element={<BuildForOffice />} />
        <Route path='/interior' element={<BuildForInterior />} />
        <Route path='/edit' element={<Edit />} />
        <Route path='/edit-office' element={<EditOffice />} />
        <Route path='/edit-interior' element={<EditInterior />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
